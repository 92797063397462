import { defineStore } from 'pinia'
import { messages } from '@/Constants'
import Conversation from '@/Core/Conversation'
import _ from 'lodash'

export const useConversationsStore = defineStore('conversations-store', {
  state: (): StateConversationStore => ({
    list: {
      active: [],
      finished: [],
      searchResult: [],
    },
    limitMessages: 10,
    markAsReadTimeout: undefined,
    finalizeConversation: null,
    transferConversation: null,
    loadingConversations: true,
  }),
  getters: {
    isReady: (state) => !state.loadingConversations,
    activeCount: (state) => {
      return state.list.active.length
    },
    finishedCount: (state) => {
      return state.list.finished.length
    },
    getContacts: (state) => {
      const allContacts = Object.values(state.list)

      // Utiliza flatMap para concatenar todos os arrays em um único array
      const allContactsArray = allContacts.flatMap((contactList) => contactList)

      return allContactsArray
    },
    unReadMessages: (state) => {
      const actives = state.list.active

      if (actives.length) {
        return actives.filter(
          (contact) => contact.props.state.pending_to_read === true,
        )?.length
      }

      return 0
    },
    unreadConversations: (state) => {
      const allContacts = Object.values(state.list)

      // Utiliza flatMap para concatenar todos os arrays em um único array
      const allContactsArray = allContacts.flatMap((contactList) => contactList)

      return allContactsArray.filter(
        (el) => el.props.state.pending_to_read === true,
      )
    },
    groupConversations: (state) => {
      const allContacts = Object.values(state.list)

      // Utiliza flatMap para concatenar todos os arrays em um único array
      const allContactsArray = allContacts.flatMap((contactList) => contactList)

      return allContactsArray.filter((el) => el.props.state.is_group === true)
    },
    filterByAttendant: (state) => {
      const contactStore = useContactStore()
      if (
        contactStore.filter !== null &&
        contactStore.view === 'filterByAttendant'
      ) {
        return state.list[contactStore.filter.filterStatusBy].filter(
          (el) => el.attendantId === contactStore.filter?.attendantId,
        )
      }

      return []
    },
    getAllConversations: (state) => {
      return () => {
        const contacts = state.list

        return new Promise<Conversation[]>((resolve) => {
          const result = []

          for (const key in contacts) {
            if (
              Object.prototype.hasOwnProperty.call(contacts, key) &&
              key !== 'searchResult'
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              result.push(...contacts[key])
            }
          }

          resolve(result)
        })
      }
    },
    getConversationByIdAndInstanceId: (state) => {
      return (props: {
        contactId: number
        instanceId: number
        status?: IPChat.ContactListTypes
      }) => {
        const { contactId, instanceId, status } = props
        const contacts = state.list
        let result: Conversation | undefined

        return new Promise<Conversation | undefined>((resolve) => {
          if (status) {
            result = contacts[status]?.find(
              (contact) =>
                contact.props.contact.id === contactId &&
                contact.props.state.instance_id === instanceId,
            )
          } else {
            const allContacts = Object.values(contacts)

            // Utiliza flatMap para concatenar todos os arrays em um único array
            const allContactsArray = allContacts.flatMap(
              (contactList) => contactList,
            )

            // Utiliza find para buscar o contato pelo ID
            result = allContactsArray.find(
              (contact) =>
                contact.props.contact.id === contactId &&
                contact.props.state.instance_id === instanceId,
            )
          }

          resolve(result)
        })
      }
    },
  },
  actions: {
    closeTransferConversation() {
      const modalsStore = useModalsStore()
      modalsStore.$patch({ openTransferModal: false })

      this.transferConversation = null
    },
    closeFinalizeConversation() {
      const modalsStore = useModalsStore()
      modalsStore.$patch({ openFinishModal: false })

      this.finalizeConversation = null
    },
    openFinalizeConversation(props: { conversation: Conversation }) {
      const { conversation } = props
      const modalsStore = useModalsStore()
      modalsStore.$patch({ openFinishModal: true })

      this.finalizeConversation = { conversation }
    },
    openTransferConversation(props: { conversation: Conversation }) {
      const { conversation } = props
      const modalsStore = useModalsStore()
      modalsStore.$patch({ openTransferModal: true })

      this.transferConversation = { conversation }
    },
    getMessageByKey(props: { contact: Conversation; messageKey: string }) {
      const { contact, messageKey } = props
      const messageMap = new Map(
        contact.messages?.map((message) => [message.key, message]),
      )

      return messageMap.get(messageKey)
    },
    clearSearchResult() {
      this.list.searchResult = []
    },
    async appendNewConversation(props: {
      event: IPChat.Socket.ChatStateInitialized
    }) {
      const { event } = props
      const { chatState, contact } = event.key

      const check = await this.getConversationByIdAndInstanceId({
        contactId: contact.id,
        instanceId: chatState.instance_id,
      })

      if (!check) {
        const stateStore = useStateStore()
        const contactStore = useContactStore()

        const checkState = stateStore.getState(chatState.id)
        if (!checkState) {
          stateStore.appendNewState({ state: chatState })
        }

        const checkContact = contactStore.getContact(contact.id)
        if (!checkContact) {
          contactStore.addNewContact({
            event: {
              key: {
                contact,
              },
            },
          })
        }

        const conversation = new Conversation({
          contact,
          state: chatState,
          messages: [],
        })

        this.list.active.unshift(conversation)
      }
    },
    async transferActiveToFinished(props: {
      contactId: number
      instanceId: number
    }) {
      const origin = this.list.active
      const destination = this.list.finished

      return new Promise<boolean>((resolve, reject) => {
        let found = false
        for (let i = 0; i < origin.length; i++) {
          if (
            origin[i].contactId === props.contactId &&
            origin[i].instanceId === props.instanceId
          ) {
            origin[i].finish()
            destination.unshift(origin[i])
            origin.splice(i, 1)
            found = true
            resolve(true)
            return
          }
        }

        if (!found) {
          console.error(
            `Object with ID ${props.contactId} and instance ID ${props.instanceId} not found in active list`,
          )
          reject(false)
        }
      })
    },
    async transferFinishedToActive(
      props: {
        contactId: number
        instanceId: number
      },
      isSearch = false,
    ) {
      const origin =
        isSearch === true ? this.list.searchResult : this.list.finished
      const destination = this.list.active

      return new Promise<boolean>((resolve, _reject) => {
        let found = false
        for (let i = 0; i < origin.length; i++) {
          if (
            origin[i].contactId === props.contactId &&
            origin[i].instanceId === props.instanceId
          ) {
            if (isSearch) {
              origin[i].setIsSearch = false
            }

            origin[i].active()
            destination.unshift(origin[i])
            origin.splice(i, 1)
            found = true
            resolve(true)
            return
          }
        }

        if (!found) {
          console.error(
            `Object with ID ${props.contactId} and instance ID ${props.instanceId} not found in finished list`,
          )
          resolve(false)
        }
      })
    },
    async deleteConversationFromContactId(props: {
      event: IPChat.Socket.DeletedContact
    }) {
      const { event } = props
      const contactId = event.key.contactId

      return new Promise<boolean>((resolve) => {
        let found = false // Variável para verificar se o objeto foi encontrado

        // Iterar sobre cada key do objeto list
        for (const key in this.list) {
          if (Object.prototype.hasOwnProperty.call(this.list, key)) {
            // Encontrar o índice do objeto que tem o contactId desejado
            const index = this.list[key].findIndex(
              (obj) => obj.contactId === contactId,
            )
            if (index !== -1) {
              // Remover o objeto do array
              this.list[key].splice(index, 1)
              found = true // Marcar que o objeto foi encontrado
              break // Sair do loop se o objeto foi encontrado e removido
            }
          }
        }

        if (found) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },
    async reactivated(props: { event: IPChat.Socket.ChatStateReactivated }) {
      const { event } = props
      const { instanceId, contactId, stateId } = event.key

      const stateStore = useStateStore()
      stateStore.fetchOne({ stateId })

      this.transferFinishedToActive({ contactId, instanceId })
    },
    async chatStateTakenFromBot(props: {
      event: IPChat.Socket.ChatStateTakenFromBot
    }) {
      const { event } = props
      const { instanceId, contactId, stateId } = event.key

      const stateStore = useStateStore()
      const contactStore = useContactStore()

      const contact = contactStore.getContact(contactId)
      if (!contact) {
        await contactStore.fetchOne({ contactId })
        this.chatStateTakenFromBot(props)
        return
      }

      const state = stateStore.getState(stateId)
      if (!state) {
        await stateStore.fetchOne({ stateId })
        this.chatStateTakenFromBot(props)
        return
      }

      const payload: IPChat.Conversation = {
        contact,
        state,
        messages: [],
      }

      const hasConversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (!hasConversation) {
        this.list[state.state].unshift(new Conversation(payload))
        await this.fetchMessages({ contactId, instanceId })
      }
    },
    async postTransferConversation(props: {
      transferEntity: 'attendant' | 'department'
      entityId: number
    }) {
      const { transferEntity, entityId } = props

      const instanceId = this.transferConversation?.conversation.instanceId
      const contactId = this.transferConversation?.conversation.contactId
      const stateId = this.transferConversation?.conversation.stateId

      if (stateId === undefined) {
        $toast.error('StateID não encontrado')
        return
      }

      if (contactId === undefined) {
        $toast.error('ContactID não encontrado')
        return
      }

      if (instanceId === undefined) {
        $toast.error('InstanceID não encontrado')
        return
      }

      try {
        await window.axios.post(
          route('v2.state.transfer', {
            entityType: transferEntity,
            _query: { stateId },
          }),
          {
            entityId,
          },
        )

        $toast.success(messages.TRANSFER_CONTACT.SUCCESS)

        const auth = useAuthStore()

        if (auth.isHimself) {
          this.unlistContact({
            event: {
              key: {
                stateId,
                contactId,
                instanceId,
              },
            },
          })
        } else if (auth.isObserver) {
          const stateStore = useStateStore()
          await stateStore.fetchOne({ stateId })
        }

        const activeChatStore = useActiveChatStore()
        if (activeChatStore.getStateId === stateId) {
          activeChatStore.clear()
        }

        this.closeTransferConversation()
      } catch (e) {
        $toast.error(messages.TRANSFER_CONTACT.ERROR)
      }
    },
    async postFinalizeConversation(props: {
      sendSatisfactionSurvey: boolean
      reasonId: number
    }) {
      const { sendSatisfactionSurvey, reasonId } = props

      const instanceId = this.finalizeConversation?.conversation.instanceId
      const contactId = this.finalizeConversation?.conversation.contactId
      const stateId = this.finalizeConversation?.conversation.stateId

      if (stateId === undefined) {
        $toast.error('StateID não encontrado')
        return
      }

      if (contactId === undefined) {
        $toast.error('ContactID não encontrado')
        return
      }

      if (instanceId === undefined) {
        $toast.error('InstanceID não encontrado')
        return
      }

      try {
        await window.axios.post(
          route('v2.state.finish', { state: stateId }),
          {
            satisfaction_survey: sendSatisfactionSurvey,
            reason_id: reasonId,
          },
          {
            'axios-retry': {
              retries: 0,
            },
          },
        )

        $toast.success(messages.FINISH_SERVICE.SUCCESS)

        const activeChatStore = useActiveChatStore()
        await this.transferActiveToFinished({ contactId, instanceId })
        if (activeChatStore.getStateId === stateId) {
          activeChatStore.clear()
        }
        this.closeFinalizeConversation()
      } catch (e) {
        $toast.error(messages.FINISH_SERVICE.ERROR)
      }
    },
    async makeConversations(props: { status: IPChat.StateStatus }) {
      const { status } = props

      const stateStore = useStateStore()
      const contactStore = useContactStore()

      const set = new Set<Conversation>()
      const stateFiltered = stateStore.manifest.filter(
        (el) => el.state === status,
      )

      for (const state of stateFiltered) {
        const contact = contactStore.getContact(state.contact_id)

        if (contact) {
          const payload: IPChat.Conversation = {
            contact,
            state,
            messages: [],
          }

          set.add(new Conversation(payload))
        }
      }

      this.list[status] = Array.from(set)

      if (status === 'active') {
        let i = 0
        for (const conversation of this.list[status]) {
          if (i <= this.limitMessages) {
            await this.fetchMessages({
              contactId: conversation.contactId,
              instanceId: conversation.instanceId,
            })
          }

          i++
        }
      }
    },
    async fetchMessages(props: { contactId: number; instanceId: number }) {
      const messages = await window.axios.get<{
        data: IPChat.PartialMessage[]
        total_messages: number
      }>(
        route('v2.message.index', {
          contact: props.contactId,
          _query: { instanceId: props.instanceId, withCount: true },
        }),
      )

      const conversation = await this.getConversationByIdAndInstanceId({
        contactId: props.contactId,
        instanceId: props.instanceId,
      })

      if (conversation) {
        conversation.totalMessages = messages.data.total_messages
        conversation.props.messages = messages.data.data.reverse()
      }
    },
    async sortContactList(
      props: { contactId: number; instanceId: number },
      pendingToRead = true,
    ) {
      const { contactId, instanceId } = props
      const activeChatStore = useActiveChatStore()

      const conversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (conversation) {
        const status = conversation.status

        if (!_.isEqual(activeChatStore.currentContact, conversation)) {
          conversation.pendingToRead = pendingToRead
        }

        if (status) {
          const copyContactlist = this.list[status]
          _.remove(copyContactlist, (object) => {
            return (
              object.contactId === contactId && object.instanceId === instanceId
            )
          })

          copyContactlist.unshift(conversation)
          this.list[status] = copyContactlist
        }
      }
    },
    async ack(props: { event: IPChat.Socket.Ack }) {
      const { event } = props
      const contactId = Number(event.key.contactId)
      const instanceId = Number(event.key.instanceId)

      const conversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (conversation) {
        const findMessage = this.getMessageByKey({
          contact: conversation,
          messageKey: String(event.key.messageKey),
        })

        if (findMessage) {
          if (
            (findMessage.status && event.ack.status > findMessage.status) ||
            !findMessage.status
          ) {
            findMessage.status = event.ack.status
          }
        }
      }
    },
    async messageEdit(props: { event: IPChat.Socket.MessageEdit }) {
      const { key, messageText } = props.event

      const contactId = key.contactId
      const instanceId = key.instanceId

      const conversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (conversation) {
        const message = conversation.getMessageByKey(key.messageKey)

        if (message && 'text' in message.message) {
          message.message.text = messageText.text
        }
      }
    },
    async failedToSendMessage(props: {
      event: IPChat.Socket.FailedToSendMessage
    }) {
      const { event } = props
      const contactId = Number(event.key.contactId)
      const instanceId = Number(event.key.instanceId)

      const conversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (conversation) {
        const findMessage = this.getMessageByKey({
          contact: conversation,
          messageKey: String(event.key.messageKey),
        })

        if (findMessage) {
          findMessage.status = MessageStatus.ERROR
          findMessage.hasError = true
        }
      }
    },
    async fishingAttendant(props: { event: IPChat.Socket.FishingAttendant }) {
      const { event } = props
      const { instanceId, contactId, chatState } = event.key

      const hasConversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      const stateStore = useStateStore()

      if (hasConversation) {
        if (hasConversation.status === 'finished') {
          _.remove(
            this.list['finished'],
            (obj) =>
              obj.contactId === contactId && obj.instanceId === instanceId,
          )
        } else {
          if (hasConversation.isSearch) {
            stateStore.updateState({ state: chatState })
          }
          return
        }
      }

      const contactStore = useContactStore()

      const contact = contactStore.getContact(contactId)
      if (!contact) {
        await contactStore.fetchOne({ contactId })
        this.fishingAttendant(props)
        return
      }

      const state = stateStore.getState(chatState.id)
      if (state) {
        // Checks if the state is the same, otherwise it updates it to what it received from the socket
        if (!_.isEqual(state, chatState)) {
          stateStore.updateState({ state: chatState })
        }
      } else {
        // Get the state that didn't exist
        await stateStore.fetchOne({ stateId: chatState.id })
        this.fishingAttendant(props)
        return
      }

      const payload: IPChat.Conversation = {
        contact,
        state,
        messages: [],
      }

      this.list[state.state].unshift(new Conversation(payload))
      await this.fetchMessages({ contactId, instanceId })

      playNotification()
      showNotification({
        title: 'Novo Atendimento',
        body: `O contato ${contact.name} está aguardando atendimento...`,
      })
    },
    async unlistContact(props: { event: IPChat.Socket.UnlistContact }) {
      const { event } = props
      const { instanceId, contactId, stateId } = event.key

      const auth = useAuthStore()

      if (auth.isObserver) {
        return
      }

      const contact = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (contact) {
        _.remove(
          this.list[contact.status],
          (obj) => obj.contactId === contactId && obj.instanceId === instanceId,
        )

        const stateStore = useStateStore()
        stateStore.unlistState({ stateId })

        const activeChatStore = useActiveChatStore()
        if (activeChatStore.getStateId === stateId) {
          activeChatStore.clear()
        }
      }
    },
    async linkConversationToAttendant(props: {
      event: IPChat.Socket.LinkConversationToAttendant
    }) {
      const { event } = props

      const chatState = event.key.chatState
      const contactId = event.key.contactId
      const instanceId = event.key.chatState.instance_id

      const hasConversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (hasConversation) {
        if (hasConversation.status === 'finished') {
          _.remove(
            this.list['finished'],
            (obj) =>
              obj.contactId === contactId && obj.instanceId === instanceId,
          )
        } else {
          return
        }
      }

      const stateStore = useStateStore()
      const contactStore = useContactStore()

      const contact = contactStore.getContact(contactId)
      if (!contact) {
        await contactStore.fetchOne({ contactId })
        this.linkConversationToAttendant(props)
        return
      }

      const state = stateStore.getState(chatState.id)
      if (state) {
        // Checks if the state is the same, otherwise it updates it to what it received from the socket
        if (!_.isEqual(state, chatState)) {
          stateStore.updateState({ state: chatState })
        }
      } else {
        // Get the state that didn't exist
        await stateStore.fetchOne({ stateId: chatState.id })
        this.linkConversationToAttendant(props)
        return
      }

      const payload: IPChat.Conversation = {
        contact,
        state,
        messages: [],
      }

      this.list[state.state].unshift(new Conversation(payload))
      await this.fetchMessages({ contactId, instanceId })

      playNotification()
      showNotification({
        title: 'Nova Mensagem',
        body: `Você recebeu uma nova mensagem de ${contact.name}`,
      })
    },
    async linkConversationToDepartment(props: {
      event: IPChat.Socket.LinkConversationToDepartment
    }) {
      const { event } = props

      const chatState = event.key.chatState
      const departmentId = event.key.departmentId
      const contactId = event.key.contactId
      const instanceId = event.key.chatState.instance_id

      const authStore = useAuthStore()

      const iBelongsDepartment =
        authStore.myDepartmentIds.includes(departmentId)

      if (authStore.canViewOnlyHimself && !iBelongsDepartment) {
        return
      }

      const hasConversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (hasConversation) {
        if (hasConversation.status === 'finished') {
          _.remove(
            this.list['finished'],
            (obj) =>
              obj.contactId === contactId && obj.instanceId === instanceId,
          )
        } else {
          return
        }
      }

      const stateStore = useStateStore()
      const contactStore = useContactStore()

      const contact = contactStore.getContact(contactId)
      if (!contact) {
        await contactStore.fetchOne({ contactId })
        this.linkConversationToDepartment(props)
        return
      }

      const state = stateStore.getState(chatState.id)
      if (state) {
        // Checks if the state is the same, otherwise it updates it to what it received from the socket
        if (!_.isEqual(state, chatState)) {
          stateStore.updateState({ state: chatState })
        }
      } else {
        // Get the state that didn't exist
        await stateStore.fetchOne({ stateId: chatState.id })
        this.linkConversationToDepartment(props)
        return
      }

      const payload: IPChat.Conversation = {
        contact,
        state,
        messages: [],
      }

      this.list[state.state].unshift(new Conversation(payload))
      await this.fetchMessages({ contactId, instanceId })

      playNotification()
      showNotification({
        title: 'Nova Mensagem',
        body: `Você recebeu uma nova mensagem de ${contact.name}`,
      })
    },
    async transferredConversation(props: {
      event: IPChat.Socket.NotifyThatTheConversationHasBeenTransferred
    }) {
      const { event } = props
      const { chatState } = event.key

      const auth = useAuthStore()

      const companyId = chatState.company_id
      const contactId = chatState.contact_id
      const instanceId = chatState.instance_id

      const execute = async () => {
        const sort = () => {
          this.sortContactList({ contactId, instanceId })
        }

        if (auth.isObserver) {
          const stateStore = useStateStore()
          stateStore.updateState({ state: chatState })
          sort()
          return
        }

        await this.fishingAttendant({
          event: {
            key: {
              companyId,
              instanceId,
              contactId,
              chatState,
            },
          },
        })
        sort()
      }

      if (
        event.transferTo === 'attendant' &&
        (chatState.attendant_id === auth.user.id || auth.isObserver)
      ) {
        execute()
      } else if (event.transferTo === 'department') {
        const authStore = useAuthStore()
        const iBelongsDepartment = authStore.myDepartmentIds.includes(
          Number(chatState.department_id),
        )

        if (iBelongsDepartment || auth.isObserver) {
          execute()
        }
      }
    },
    async unfishAttendant(props: { event: IPChat.Socket.UnfishAttendant }) {
      const { event } = props
      const { instanceId, contactId, stateId } = event.key
      const auth = useAuthStore()

      if (auth.isObserver) {
        // Não faz o unfish apenas altera o state pois o usuário logado é um observer
        const stateStore = useStateStore()
        stateStore.fetchOne({ stateId })
        return
      }

      const contact = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (contact) {
        _.remove(
          this.list[contact.status],
          (obj) => obj.contactId === contactId && obj.instanceId === instanceId,
        )

        const activeChatStore = useActiveChatStore()
        if (activeChatStore.getStateId === stateId) {
          activeChatStore.clear()
        }
      }
    },
    async newMessage(
      props: { event: IPChat.Socket.NewMessage },
      notify = true,
    ) {
      const { event } = props
      let { message } = event.key
      const { instanceId, contactId } = event.key

      const contact = await this.getConversationByIdAndInstanceId({
        instanceId,
        contactId,
      })

      if (contact) {
        if (contact.messages.length === 0) {
          await this.fetchMessages({ contactId, instanceId })
          const pendingToRead = message.fromContact
          await this.sortContactList({ contactId, instanceId }, pendingToRead)
          return
        }

        const checkMessageKey = contact.messages.find(
          (msg) => msg.key === message.key,
        )

        if (!checkMessageKey) {
          if (message?.content) {
            message = {
              ...message,
              message: message.content,
            }

            delete message.content
          }

          contact.messages.push(message)
          const pendingToRead = message.fromContact
          await this.sortContactList({ contactId, instanceId }, pendingToRead)

          this.markCurrentChatAsRead({
            conversation: contact,
            messageId: message.key,
          })

          if (contact.isActive && notify) {
            playNotification()
            showNotification({
              title: 'Nova Mensagem',
              body: `Você recebeu uma nova mensagem de ${contact.contactName}`,
            })
          }
        }
      }
    },
    async reactionMessage(props: { event: IPChat.Socket.ReactionMessage }) {
      const { messageKey, contactId, instanceId } = props.event.key
      const { id: reactionId, reaction: eventReaction } = props.event.reaction

      const conversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (!conversation) {
        return
      }

      const message = conversation.getMessageByKey(messageKey)

      if (!message) {
        return
      }

      const reaction = message.reactions?.find((rc) => rc.id === reactionId)

      if (reaction) {
        if (_.isEmpty(eventReaction)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          _.remove(message.reactions, (rc) => rc.id === reactionId)
        } else {
          reaction.reaction = props.event.reaction.reaction
        }

        return
      }

      if (!_.isEmpty(eventReaction)) {
        if (!message.reactions?.length) {
          message.reactions = []
        }

        message.reactions.push({
          id: reactionId,
          reaction: props.event.reaction.reaction,
        })
      }
    },
    async revokeMessage(props: { event: IPChat.Socket.RevokeMessage }) {
      const { contactId, instanceId, messageKey } = props.event.key

      const conversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (conversation) {
        const message = conversation.getMessageByKey(messageKey)

        if (message) {
          message.message = {}
        }
      }
    },
    async updateMessage(props: {
      event: IPChat.Socket.SetExternalIdOnMessage
    }) {
      const { event } = props
      const contactId = Number(event.key.contactId)
      const instanceId = Number(event.key.instanceId)

      const conversation = await this.getConversationByIdAndInstanceId({
        contactId,
        instanceId,
      })

      if (conversation) {
        const findMessage = this.getMessageByKey({
          contact: conversation,
          messageKey: String(event.message.key || event.key.messageKey),
        })

        if (findMessage) {
          delete event.message.status
          _.assignIn(findMessage, event.message)
        }
      }
    },
    async handleSearch(props: { results: IPChat.SearchContactResult[] }) {
      const { results: searchResult } = props

      if (this.list.searchResult.length) {
        this.clearSearchResult()
      }

      const set = new Set<Conversation>()
      for (const item of searchResult) {
        if (item.state) {
          const conversation = await this.getConversationByIdAndInstanceId({
            contactId: item.contact.id,
            instanceId: item.state.instance_id,
          })

          if (conversation) {
            //this.list.searchResult.push(conversation)
            set.add(conversation)
          } else {
            // VERIFICAR COM O JULIO O QUE FAZER QUANDO A PESSOA CLICAR NUM CONTATO QUE NÃO PERTENCE A ELA (USUÁRIO QUE PODE FILTAR TODO MUNDO)
            item.state.isSearch = true
            const stateStore = useStateStore()
            stateStore.appendNewState({ state: item.state })

            const state = stateStore.getState(item.state.id)

            if (state) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const newConversation = new Conversation({
                contact: item.contact,
                state,
              })
              set.add(newConversation)
              //this.list.searchResult.push(newConversation)
            }
          }

          continue
        }

        const newConversation = new Conversation({
          contact: item.contact,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state: {},
        })
        set.add(newConversation)
        //this.list.searchResult.push(newConversation)
      }

      this.list.searchResult = Array.from(set)
    },
    async handleFilterByTag(props: { tagId: number }) {
      const { tagId } = props

      if (this.list.searchResult.length) {
        this.list.searchResult = []
      }

      const allConversation = await this.getAllConversations()
      const filteredConversation = allConversation.filter((el) =>
        el.tags?.some((tag) => tag.id === tagId),
      )
      for (const item of filteredConversation) {
        this.list.searchResult.push(item)
      }
    },
    markCurrentChatAsRead(props: {
      conversation: Conversation
      messageId?: number | string
    }) {
      const { conversation, messageId } = props

      const useActiveChat = useActiveChatStore()
      const useAuth = useAuthStore()

      if (
        useActiveChat.isContactOpen(conversation.stateId) &&
        conversation.attendantId === useAuth.user.id &&
        !conversation.isGroup
      ) {
        // Verifica contato
        clearTimeout(this.markAsReadTimeout)
        this.markAsReadTimeout = setTimeout(
          () => markAsRead(conversation.stateId),
          2000,
        )
      } else if (
        useActiveChat.isContactOpen(conversation.stateId) &&
        conversation.isGroup
      ) {
        // Verifica grupo
        clearTimeout(this.markAsReadTimeout)
        if (messageId) {
          this.markAsReadTimeout = setTimeout(
            () => markGroupAsRead(conversation.contactId, messageId),
            2000,
          )
        }
      }
    },
  },
})

interface StateConversationStore {
  list: ConversationListStatusStore
  finalizeConversation: FinalizeConversation
  transferConversation: TransferConversation
  loadingConversations: boolean
  markAsReadTimeout?: NodeJS.Timeout
  limitMessages: number
}

interface ConversationListStatusStore {
  active: Conversation[]
  finished: Conversation[]
  searchResult: Conversation[]
  [key: string]: Conversation[]
}

type FinalizeConversation = {
  conversation: Conversation
} | null

type TransferConversation = {
  conversation: Conversation
} | null
