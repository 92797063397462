import { defineStore } from 'pinia'

export const useDashboardStore = defineStore('dashboard-store', {
  state: () => ({
    scheduledMessages: {
      messageList: [] as ScheduledMessages[],
      fetching: false,
    },
  }),
  actions: {
    fetchScheduledMessages() {
      this.scheduledMessages.fetching = true
      window.axios
        .get<ScheduledMessages[]>(route('dashboard.loadScheduledMessages'))
        .then((response) => {
          this.scheduledMessages.messageList = response.data
        })
        .finally(() => {
          this.scheduledMessages.fetching = false
        })
    },
  },
})
