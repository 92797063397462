import { acceptHMRUpdate, defineStore } from 'pinia'

export const useModalsStore = defineStore('modals-store', {
  state: (): StateModalsStore => ({
    openFinishModal: false,
    openTransferModal: false,
    openContactEdit: false,
    openTagAssign: false,
    openCommentsModal: false,
    openInitializeState: false,
    openNewContactModal: false,
    openForwardMessageModal: false,
    openFilterByAttendantModal: false,
    openMessageSchedulingModal: false,
    openMessageSchedulingViewModal: false,
    openMessageSchedulingEditModal: false,
    forwardMessageMetadata: {
      fromMessageKey: null,
    },
    revokeMessage: {
      props: null,
      opened: false,
    },
  }),
  actions: {
    toggleRevokeMessage(props?: RevokeProps) {
      if (props) {
        this.revokeMessage.props = props
        this.revokeMessage.opened = true
        return
      }

      this.revokeMessage.props = null
      this.revokeMessage.opened = false
    },
    toggleNewContactModal() {
      this.openNewContactModal = !this.openCommentsModal
    },
    toggleFilterByAttendantModal() {
      this.openFilterByAttendantModal = !this.openFilterByAttendantModal
    },
    toogleForwardMessageModal() {
      this.openForwardMessageModal = !this.openForwardMessageModal

      if (!this.openForwardMessageModal) {
        this.forwardMessageMetadata.fromMessageKey = null
      }
    },
    setForwardMessageMetadata(props: { fromMessageKey: string }) {
      this.forwardMessageMetadata.fromMessageKey = props.fromMessageKey
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalsStore, import.meta.hot))
}

interface StateModalsStore {
  openFinishModal: boolean
  openTransferModal: boolean
  openContactEdit: boolean
  openTagAssign: boolean
  openCommentsModal: boolean
  openInitializeState: boolean
  openNewContactModal: boolean
  openForwardMessageModal: boolean
  openFilterByAttendantModal: boolean
  openMessageSchedulingModal: boolean
  openMessageSchedulingViewModal: boolean
  openMessageSchedulingEditModal: boolean
  forwardMessageMetadata: {
    fromMessageKey: null | string
  }
  revokeMessage: {
    props: RevokeProps
    opened: boolean
  }
}

type RevokeProps = { messageKey: string; stateId: number } | null
