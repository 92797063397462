import { defineStore } from 'pinia'

export const useFlashMessages = defineStore('flashMessages', {
  state: (): FlashMessage => ({
    message: null,
    type: 'info',
    scope: 'global',
    expire: 10_000,
  }),
  getters: {
    hasNotice: (state) => state.message,
    isGlobal: (state) => state.scope === 'global',
    success: (state) => state.type === 'success',
    info: (state) => state.type === 'info',
    warning: (state) => state.type === 'warning',
    error: (state) => state.type === 'error',
    noticeType: (state) => state.type,
  },
  actions: {
    resetNotice() {
      this.message = null
      this.type = null
      this.scope = 'global'
    },
    setNotice(props: Replace<FlashMessage, { message: string }>) {
      this.message = props.message
      this.type = props.type

      if (props.scope) {
        this.scope = props.scope
      }

      setTimeout(() => this.resetNotice(), props.expire ?? this.expire)
    },
    setLocalNotice(
      props: Omit<Replace<FlashMessage, { message: string }>, 'scope'>,
    ) {
      this.scope = 'local'
      this.message = props.message
      this.type = props.type

      setTimeout(() => this.resetNotice(), props.expire ?? this.expire)
    },
  },
})

interface FlashMessage {
  message?: string | null
  type?: 'success' | 'info' | 'warning' | 'error' | null
  scope?: 'global' | 'local'
  expire?: number
}
