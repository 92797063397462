import { defineStore } from 'pinia'
import { MilliSeconds } from '@/Enums/MilliSeconds'
import { useStorage, useTimeoutPoll } from '@vueuse/core'
import _ from 'lodash'
import logger from '@/Helpers/Logger'
import Queue from 'queue'

export const useAuthStore = defineStore('auth-store', {
  state: () => ({
    user: {} as IPChat.Auth.User,
    company: {} as IPChat.Auth.Company,
    companies: [] as IPChat.Company[],
    instances: [] as IPChat.Instance[],
    myDepartments: [] as IPChat.Department[],
    departments: [] as IPChat.Department[],
    tags: [] as IPChat.Tag[],
    presenceList: [] as IPChat.Auth.PresenceList[],
    socketIsReady: false,
    config: {
      notification: {
        alert: useStorage('notificationAlert', true, localStorage),
        sound: useStorage('notificationSound', true, localStorage),
      },
    },
  }),
  getters: {
    isAdmin: (state) => state.company.pivot?.role === 'admin',
    isAll: (state) => state.company.pivot?.contacts_view === 'all',
    isHimself: (state) => state.company.pivot?.contacts_view === 'himself',
    canViewOnlyHimself: (state) =>
      state.company.pivot?.contacts_view === 'himself',
    showShortTag: (state) => state.company.setting?.show_short_tag,
    avatar: (state) => state.user?.avatar,
    isObserver: (state) =>
      state.company.pivot?.contacts_view === 'all' ||
      state.company.pivot?.role === 'admin',
    allowedToFollow: (state) =>
      state.company.pivot?.contacts_view === 'all' ||
      state.company.pivot?.role === 'admin',
    showAttendantName: (state) => state.company.setting?.show_attendant_name,
    hasCompanyServiceTime: (state) =>
      state.company.setting?.service_time ?? false,
    satisfactionSurveyIsEnabled: (state) =>
      state.company.setting?.satisfaction_survey ?? false,
    forceSendSatisfactionSurvey: (state) =>
      state.company.setting?.force_satisfaction_survey ?? false,
    myDepartmentIds: (state) => state.myDepartments.map((el) => el.id),
    hasManyCompanies: (state) => state.companies.length > 1,
    hasManyInstances: (state) => state.instances.length > 1,
    allowDeleteMessage: (state) => {
      if (state.company.pivot?.role === 'admin') {
        return true
      }

      return state.company.setting?.allow_delete_messages
    },
    isInvalidSession: (state) => {
      return (currentCompanyId?: number) =>
        !_.isEqual(currentCompanyId, state.company?.id) &&
        state.company?.id !== undefined
    },
    getInstanceById: (state) => {
      return (id: number) =>
        state.instances.find((instance) => instance.id === id)
    },
    getDepartmentById: (state) => {
      return (id: number) => state.departments.find((dpto) => dpto.id === id)
    },
  },
  actions: {
    toggleCompanyStatus(disabled: boolean) {
      this.company.disabled = disabled
    },
    init() {
      const q = new Queue({ results: [], autostart: true, concurrency: 2 })

      this.getSettings()

      q.push(
        (cb) => {
          useTimeoutPoll(
            async () => {
              await this.getInstances(true)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              cb()
            },
            MilliSeconds.FIVE_MINUTES,
            { immediate: true },
          )
        },
        (cb) => {
          useTimeoutPoll(
            async () => {
              await this.getCompanies(true)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              cb()
            },
            MilliSeconds.FIVE_MINUTES,
            { immediate: true },
          )
        },
        (cb) => {
          useTimeoutPoll(
            async () => {
              await this.getDepartments(true)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              cb()
            },
            MilliSeconds.FIVE_MINUTES,
            { immediate: true },
          )
        },
        (cb) => {
          useTimeoutPoll(
            async () => {
              await this.getTags(true)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              cb()
            },
            MilliSeconds.FIVE_MINUTES,
            { immediate: true },
          )
        },
        (cb) => {
          useTimeoutPoll(
            async () => {
              await this.getMyDepartments(true)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              cb()
            },
            MilliSeconds.FIVE_MINUTES,
            { immediate: true },
          )
        },
      )
    },
    reset() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.user = {}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.company = {}
      this.presenceList = []
    },
    makeSession(auth: {
      user: IPChat.Auth.User
      company: IPChat.Auth.Company
    }) {
      this.user = auth.user
      this.company = auth.company
    },
    // getCompanyPlan() {
    //   window.axios.get(route('plan')).then((response) => {
    //     this.company.plan = response.data
    //   })
    // },
    getSettings() {
      window.axios
        .get<IPChat.CompanySetting>(route('system.resources.getSettings'))
        .then((r) => {
          this.company.setting = r.data
        })
    },
    async getCompanies(forceSync = false) {
      if (!_.isEmpty(this.companies) && !forceSync) {
        return this.companies
      }

      try {
        const { data } = await window.axios.get<IPChat.Company[]>(
          route('system.resources.getCompanies'),
        )
        this.companies = data
      } catch (e) {
        const message =
          'Houve um erro na listagem de empresas, tente novamente!'
        logger.error(e, message)
        $toast.error(message)
      }

      return this.companies
    },
    async getInstances(forceSync = false) {
      if (!_.isEmpty(this.instances) && !forceSync) {
        return this.instances
      }

      try {
        const { data } = await window.axios.get<IPChat.Instance[]>(
          route('system.resources.getInstances'),
        )
        this.instances = data
      } catch (e) {
        const message =
          'Houve um erro na listagem de instâncias, tente novamente!'
        logger.error(e, message)
        $toast.error(message)
      }

      return this.instances
    },
    async getDepartments(forceSync = false) {
      if (!_.isEmpty(this.departments) && !forceSync) {
        return this.departments
      }

      try {
        const { data } = await window.axios.get<IPChat.Department[]>(
          route('system.resources.getDepartments'),
        )
        this.departments = data
      } catch (e) {
        const message =
          'Houve um erro na listagem de departamentos, tente novamente!'
        logger.error(e, message)
        $toast.error(message)
      }

      return this.departments
    },
    async getTags(forceSync = false) {
      if (!_.isEmpty(this.tags) && !forceSync) {
        return this.tags
      }

      try {
        const { data } = await window.axios.get<IPChat.Tag[]>(
          route('system.resources.getTags'),
        )
        this.tags = data
      } catch (e) {
        const message =
          'Houve um erro na listagem de etiquetas, tente novamente!'
        logger.error(e, message)
        $toast.error(message)
      }

      return this.tags
    },
    async getMyDepartments(forceSync = false) {
      if (!_.isEmpty(this.myDepartments) && !forceSync) {
        return this.myDepartments
      }

      try {
        const { data } = await window.axios.get<IPChat.Department[]>(
          route('profile.myDepartments'),
        )
        this.myDepartments = data
      } catch (e) {
        const message =
          'Houve um erro na listagem de departamentos do usuário, tente novamente!'
        logger.error(e, message)
        $toast.error(message)
      }

      return this.myDepartments
    },
    setPresence(user: IPChat.Auth.PresenceList) {
      const findIndex = this.presenceList.findIndex((el) => el.id === user.id)
      if (findIndex === -1) {
        this.presenceList.push(user)
      }
    },
    removePresence(user: IPChat.Auth.PresenceList) {
      const findIndex = this.presenceList.findIndex((el) => el.id === user.id)
      if (findIndex > -1) {
        this.presenceList.splice(findIndex, 1)
      }
    },
  },
})
