import { defineStore } from 'pinia'
import _ from 'lodash'
import Conversation from '@/Core/Conversation'

export const useStateStore = defineStore('state-store', {
  state: (): StateStore => ({
    manifest: [],
    initializeState: null,
    isLoading: false,
    total: 0,
  }),
  getters: {
    hasState: (state) => {
      return (id: number) => state.manifest.some((state) => state.id === id)
    },
    getState: (state) => {
      return (id: number): IPChat.State | undefined =>
        state.manifest.find((state) => state.id === id)
    },
    loadingState: (state) => {
      if (state.total === 0) return 0
      let loaded = (state.manifest.length / state.total) * 100
      loaded = Math.round(loaded * 100) / 100

      return loaded
    },
  },
  actions: {
    closeInitializeState() {
      const modalStore = useModalsStore()
      modalStore.openInitializeState = false
      this.initializeState = null
    },
    openInitializeState(props: { conversation: Conversation }) {
      const { conversation } = props

      if (!_.isEmpty(conversation.props.state)) {
        $toast.error(
          'Houve um erro ao abrir o contato (State já existe), tente novamente!',
        )
        return
      }

      const modalStore = useModalsStore()
      modalStore.openInitializeState = true
      this.initializeState = conversation
    },
    updateState(props: { state: IPChat.State }) {
      const { state: newState } = props
      const state = this.getState(newState.id)

      if (state) {
        _.assignIn(state, newState)
      }
    },
    appendNewState(props: { state: IPChat.State }) {
      const state = this.getState(props.state.id)

      if (!state) {
        this.manifest.push(props.state)
      }
    },
    markAsUnread(props: { stateId: number; ignoreSyncToBackend?: boolean }) {
      /**
       * ignoreSyncToBackend
       * Se o evento vem através do socket, a sincronização com o backend deve ser ignorada
       */
      const state = this.getState(props.stateId)
      if (!state) return

      state.pending_to_read = true

      if (!props.ignoreSyncToBackend) {
        markAsUnread(props.stateId)
      }
    },
    markAsRead(props: { stateId: number }) {
      const state = this.getState(props.stateId)
      if (!state) return

      state.pending_to_read = false
    },
    unlistState(props: { stateId: number }) {
      const state = this.getState(props.stateId)
      if (!state) return

      _.remove(this.manifest, (el) => el.id === props.stateId)
    },
    async postInitializeState(props: { instanceId: number }) {
      if (!this.initializeState) {
        return
      }

      const { instanceId } = props
      const contactId = this.initializeState.contactId

      try {
        const { data: state } = await window.axios.post<IPChat.State>(
          route('v2.state.initialize', { _query: { instanceId, contactId } }),
        )

        this.appendNewState({ state })
        this.initializeState.state = state

        const conversationStore = useConversationsStore()
        conversationStore.list.active.unshift(this.initializeState)
        this.closeInitializeState()
      } catch (e: any) {
        const error =
          'error' in e.response.data
            ? e.response.data.error
            : 'message' in e.response.data
              ? e.response.data.message
              : 'Não foi possível iniciar o atendimento, tente novamente!'
        $toast.error(error)
      }
    },
    async fetchOne(props: { stateId: number }) {
      const { stateId } = props

      try {
        const { data } = await window.axios.get<IPChat.State>(
          route('v2.state.fetchOne', { _query: { stateId } }),
        )

        const state = this.getState(data.id)

        if (state) {
          _.assignIn(state, data)
        } else {
          this.manifest.push(data)
        }
      } catch (e) {
        console.error(e)
      }
    },
    async syncManifest(props: {
      page?: number
      status: IPChat.StateStatus | IPChat.StateStatus[]
    }) {
      if (!props?.page) {
        props = {
          ...props,
          page: 1,
        }
      }

      if (!this.isLoading) {
        this.isLoading = true
      }

      const { data } = await useStateManifest({
        page: props.page,
        status: props.status,
      })

      if (this.total === 0) {
        this.total = data.total
      }

      const requestCurrentPage = data.current_page
      const requestLastPage = data.last_page

      for (const state of data.data) {
        if (!this.hasState(state.id)) {
          this.manifest.push(state)
        }
      }

      if (requestLastPage > 1 && requestCurrentPage < requestLastPage) {
        await this.syncManifest({
          ...props,
          page: Number(props.page) + 1,
        })

        return
      }

      this.isLoading = false
    },
  },
})

interface StateStore {
  manifest: IPChat.State[]
  initializeState: Conversation | null
  isLoading: boolean
  total: number
}
